import React from 'react'
import s from './AboutUs.module.css'
import puzzles from '../../assets/img/puzzless.png'
import puzzlesMobile from '../../assets/img/mobile/puzzless.png'
import ai_img from '../../assets/img/ai_img.png'
import future_finance from '../../assets/img/future_finance.png'
import coin from '../../assets/img/bitcoin.png'
import BTC from '../../assets/img/BTC.png'
import CustomBtnPink from '../../components/ui/customBtnPink/CustomBtnPink'
import { OPEN_ACCOUNT } from '../../router/Constants'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import Hero from '../../components/hero/Hero'

const AboutUs = () => {
  const navigate = useNavigate()

  return (
    <div className={s.wrapper}>
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className={s.motion_div}>

        <div className={s.content}>
          <Hero title_1='About' title_2='Wall Street IBU' description='Wall Street IBU is an international private bank! The Bank is legally incorporated in Fomboni, Comoros Islands, a premier offshore finance hub, and has a team of International Private Bankers working digitally around the world providing almost 24/7 service.'/>
        </div>

          <div className={s.content}>
            <div className={s.flex}>
              <div className={s.left}>
                <img src={ai_img} alt="ai" />
              </div>
              <div className={s.right}>
                <p>For over two decades, our predecessors have built a reputation for confidence, reliability, and strategic partnerships. In July 2022, we embarked on a new chapter with the merger of two pillars of financial expertise: Jilin Jiutai Rural Commercial Bank Co., Ltd. , a leading Chinese commercial Bank with a deep history, and MC Financial Services Limited, a global financial force with roots in the Asia Pacific region. This union, driven by a shared vision of international growth, created Wall Street Bank to deliver a new standard of international financial services.</p>
                <p>We provide flexible financial services to individuals and businesses with an international presence. An offshore account offers flexibility, discretion and in many cases enhanced security. We offer specialized services related to digital assets, including escrow services, and financing of real estate in prime cities and resort areas worldwide.</p>
              </div>
            </div>
          </div>

        <div className={s.content}>
          <div className={s.puzzles_block}>
            <div className={s.flex}>
              <div className={s.puzzles_img_block}>
                <img className={s.puzzles_mob} src={puzzlesMobile} alt='' />
                <img className={s.puzzles} src={puzzles} alt='' />
              </div>
              <div className={s.puzzles_description_block}>
                <h2 className={s.puzzles_block_title}>
                  Your Global Financial Partner
                </h2>
                <div className={s.puzzles_description_content}>
                  Wall Street Bank is designed for individuals and businesses
                  seeking flexible financial solutions in a connected world. Our
                  offshore accounts offer:
                </div>
                <div className={s.puzzles_description_content}>
                  <div className={s.puzzles_description_row}>
                    <span className={s.bold}>Unparalleled Flexibility: </span>
                    Tailored solutions to meet your unique needs, with a focus
                    on international opportunities.
                  </div>
                  <div className={s.puzzles_description_row}>
                    <span className={s.bold}>
                      Enhanced Privacy & Security:{' '}
                    </span>
                    Protect your assets and financial information with our
                    robust offshore framework and commitment to confidentiality.
                  </div>
                  <div className={s.puzzles_description_row}>
                    <span className={s.bold}>Strategic Advantages: </span>
                    Benefit from a stable and secure environment, ideal for
                    managing and growing your global wealth.
                  </div>
                </div>
                <div className={s.puzzles_button_block}>
                  <div className={s.button_wrapper}>
                    <CustomBtnPink
                      title={'Sign Up'}
                      onClick={() => navigate(OPEN_ACCOUNT)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={s.content}>
          <div className={s.flex}>
            <div className={s.left}>
              <p className={s.bold}>Specializing in the Future of Finance</p>
              <p>We are at the forefront of the evolving financial landscape, offering specialized services that encompass the digital age:</p>
              <p><span className={s.bold}>Digital Asset Escrow:</span> Securely manage your digital assets with our robust escrow services, designed for the evolving financial landscape.</p>
              <p><span className={s.bold}>Global Real Estate Financing:</span> Access financing for prime properties in major cities and resort destinations worldwide, making your real estate dreams a reality.</p>
            </div>
            <div className={s.right}>
              <img src={future_finance} alt="ai" />
            </div>
          </div>
        </div>

        <div className={s.coin_block}>
          <div className={s.content}>
            <div className={s.text}>
              <p>Finally, <span className={s.italic}>Wall Street IBU</span> works with crypto and blockchain companies to provide them with full-service multi-currency banking, asset management, trading and advisory. We are one of the very few offshore regulated financial banks that support blockchain and digital assets.</p>
              <div className={s.coin}>
                <img src={BTC} alt='' />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default AboutUs
