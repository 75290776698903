import React, {CSSProperties} from 'react';
import s from './CustomBtnBlue.module.css';

type PropsType = {
    title: string,
    onClick?: (e?: any) => void;
    type?: string
    style?: CSSProperties
}
const CustomBtnBlue:React.FC<PropsType> = ({title, type, ...props}) => {
    return (
        <button className={s.my_btn} {...props}>{title}</button>
    );
};

export default CustomBtnBlue;
