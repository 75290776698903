import React from "react";
import s from './Hero.module.css'
import CustomBtnBlue from "../ui/customBtnBlue/CustomBtnBlue";
import CustomBtnBlueTransparent from "../ui/customBtnBlueTransparent/CustomBtnBlueTransparent";
import { useNavigate } from "react-router-dom";
import { ABOUT_US, OPEN_ACCOUNT } from "../../router/Constants";

type PropsType = {
  title_1: string,
  title_2?: string,
  description: string,
  buttons?: boolean,
}

const Hero:React.FC<PropsType> = ({title_1, title_2, description, buttons}) => {
  const navigate = useNavigate()
  return (
    <div className={s.hero}>
      <h1><span className={s.title_1}>{title_1}</span> <span className={s.title_2}>{title_2}</span></h1>
      <p className={s.description}>{description}</p>
      {buttons && (
        <div className={s.buttons}>
        <CustomBtnBlue title="get started" onClick={()=>navigate(OPEN_ACCOUNT)} />
        <CustomBtnBlueTransparent title="learn more" onClick={()=>navigate(ABOUT_US)} />
      </div>
      )}
    </div>
  );
};

export default Hero;
