import React from 'react';
import s from "./CommercialBanking.module.css";
import { motion } from 'framer-motion';
import comercial_image from '../../assets/img/comercialBanking.png'
import Hero from '../../components/hero/Hero';

const CommercialBanking = () => {
    return (
        <div className={s.wrapper}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >
                <div className={s.content}>
                    <Hero title_1='Commercial' title_2='Banking' description='Wall Street IBU is an international private bank! The Bank is incorporated in Fomboni, Comoros Islands, and has a team of International Private Bankers working digitally around the world providing almost 24/7 service.'/>

                    <div className={s.comercial_block_desc}>
            <div className={s.comercial_block_desc_left}>
            <img className={s.desc_image} src={comercial_image} alt="comercial_block Description" />
            </div>
            <div className={s.comercial_block_desc_right}>
            
            <p className={s.comercial_block_desc_paragraph}>
            Businesses can also benefit from the private banking touch! Our International Commercial Banking services are designed for enterprises that are run by globally mobile families. If you are looking for a reliable global trade partner to help you handle transactions and create wealth across borders, talk to our expert bankers in the areas of complex payment transactions and trade finance. With our international expertise and network, we are a valued partner when it comes to accurately assessing markets  and managing risks.
              </p>
              <p className={s.comercial_block_desc_paragraph}>
              As a bank we are a specialist in niche and emerging markets. We provide customized solutions to multi-jurisdictional entities, individuals, and families to protect and enhance wealth. We enable them to execute business transactions across the globe in a secure, efficient and transparent manner. As a trusted partner for modern offshore banking, our solutions are based on the latest and secure technological platforms. We support your global success and ensure your international transactions reach their destination at all times.
              </p>
              <p className={s.comercial_block_desc_paragraph}>
              Our Commercial Banking division is ideal for complex, multi-jurisdictional businesses who are often “derisked” by large international banks. We are (almost) always able to find a regulation-compliant solution. We can boast outstanding specialist expertise in the field of digital assets and blockchain banking, in the development of fund solutions, in tokenization, in the setting up of capital market issues – and, of course, also in conventional commercial banking. We also provide a wealth of expertise in the field of HNWI mortgages, for example with personal loans secured by a charge over company assets.
              </p>
            </div>
          </div>

                    <div className={s.services_list_block}>
                        <div className={s.services_list_block_title}>
                            Our Corporate Multi-Currency Account (MCA) is our flagship product for international
                            companies.
                            Our External Asset Managers Desk works closely with financial professionals and fiduciaries.
                            <div>
                                Your MCA will enable you to:
                            </div>
                        </div>
                        <div className={s.flex}>
                            <div className={s.left}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Conduct transactions in most major world currencies
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Consolidate and manage foreign receivables and payments more efficiently
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Send and receive wire payments and settle your credit card bills without
                                        currency
                                        conversion
                                    </div>
                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        View balances and activities for all currency accounts online
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Allow for currency diversification as an investment option including our “Invest
                                        Loan”
                                        program
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.gradient}>
                        <div className={s.gradient_title}>
                            Non-resident companies, trusts, protected cell companies and the like can all benefit the
                            following
                            core private banking services, delivered through a secure and cutting edge digital channel:
                        </div>
                        <div className={s.flex}>
                            <div className={s.left}>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                        Multi-currency deposit account supporting all major and some exotic currencies
                                        plus
                                        crypto currencies. Professional management of your payment transactions by an
                                        experienced
                                        back-office team
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                        Compliance-integrated process for a secure and punctual payment
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                        Segregated sub-accounts for projects or client funds
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                        Custody of digital assets and execution services
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                        Trading and brokerage
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                        Bespoke investment solutions
                                    </div>
                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                        Foreign Exchange – spot, forward, swap and lending
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                        OTC and Derivatives Instruments
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                        Precious Metals Trading, Custody and Lending
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                        Payments, Transfers, and Credit Cards
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                        Lombard loans and mortgages secured by digital assets, securities and cash
                                        balances
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                        Business Concierge services
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.gradient_footer}>
                            All clients will be allocated a private banker so they know immediately who to contact
                            whenever
                            any banking services are needed. Our private bankers speak most global languages. You can
                            initiate
                            the account opening process online, or find out more about our Transaction
                            Banking/Commercial Banking
                            offer by booking an online appointment with our International Commercial Banking team today!
                        </div>
                    </div>

                </div>
            </motion.div>
        </div>
    );
};

export default CommercialBanking;
