import React from 'react'
import s from './Faq.module.css'
import { Collapse, theme } from 'antd'
import plus from '../../assets/img/plus.svg'
import minus from '../../assets/img/minus.png'
import { motion } from 'framer-motion'
import Contact from '../contact/Contact'
import Hero from '../../components/hero/Hero'

const { Panel } = Collapse

const dataForCollapse = [
  {
    title:
      'Can I apply for a Bank account online or do I have to visit your offices?',
    text: `Yes you can apply and be approved online. Wall Street Ibu is a digital bank. All bank account applications 
            are handled online. Personal visits to the Bank’s headquarters or any of the Bank’s branches located around
            the world are welcome, but not required.\n
            After applying for your account online, you will be contacted by the Bank’s International Private Banking 
            or International Commercial banking officers to complete an interview online before the process is finalized
            and account number is allocated. \n
            At Wall Street Ibu we pride ourselves on flexibility and bespoke solutions. Our service is aimed at 
            wealthy, international individuals who are likely to deposit $1 million upwards. For us the most important thing 
            is that our business relationship should be mutually beneficial. This is only fair and applies both ways. Due to 
            the high level of personal service we provide, and due to the fees we charge for our services, it is not worth 
            our while to service very small accounts. Smaller clients will probably find our fees disproportionate and would 
            perhaps be better served by a domestic bank in their home countries or a fintech/EMI/PSP type institution rather 
            than a fully regulated bank. However, each case is different and we are cognizant that a small account may bring
            substantial business in the future as clients may be expecting a liquidity event or may be in a position to refer 
            in other larger clients. Therefore we do not have any fixed minimum deposit and we are happy to open accounts 
            with zero balance provided onboarding fees are paid according to our standard tariff.`,
    key: '1',
  },
  {
    title: 'How long does it take to open an account?',
    text: `We can typically open International Private Banking (personal) Multi-Currency Accounts within 24-48 hours 
        and International Commercial Accounts within 1-2 weeks. In some cases it may be possible to open accounts faster 
        in case of urgent need. On the other hand, in some cases account opening may take longer if extended due diligence 
        procedures are required. Please discuss your individual circumstances with our International Private Bankers.`,
    key: '2',
  },
  {
    title: 'Can I transfer my existing investment portfolio to the Bank?',
    text: `Yes, you can. Bank customers can transfer their existing investment portfolio such as stocks, bonds, 
        mutual funds, precious metals, digital assets, private equity, and other products to the Bank through our 
        collaboration with various global asset custodian banks. Your International Private Banker will be able to make 
        arrangements and inform you of the exact procedures to follow depending on the asset classes involved.`,
    key: '4',
  },
  {
    title: 'Can the Bank help me to register a new company or trust?',
    text: `The Bank itself does not handle incorporations directly but we work very closely with incorporation agents 
        in the various jurisdictions where we work. This allows us to share due diligence (you only need to provide one 
        set of documents) and have the bank account ready on the same day the incorporation certificate is issued. In many
         cases it is beneficial for a client to open a corporate rather than a personal account. Please discuss your 
         individual requirements with your Wall Street Ibu International Private Banker.`,
    key: '5',
  },
  {
    title: 'What are your wire instructions for inbound SWIFT transfers?',
    text: `All Wall Street Ibu accounts are Multi-Currency Accounts and can handle various currencies for inbound and 
        outbound transfers. Unfortunately the international banking system is not as advanced as we are. If you send 
        payments without intermediary bank information there is a high chance they will be lost, delayed, involuntarily 
        converted to another currency and/or have large fees deducted by intermediary banks over which we have no control. 
        These intermediary banks are chosen by the bank that is sending the funds, not by Wall Street Ibu.
        In order to receive funds in your account. we therefore need to provide you with different transaction instructions 
        depending on factors such as the currency, source, and type of your payment. Your Wall Street Ibu International 
        Private Banker will send you instructions for US dollar and Euro deposits as soon as your account is opened. 
        If you require wire instructions for another currency you simply need to ask.`,
    key: '6',
  },
  {
    title: 'Do you support the SWIFT GPI system?',
    text: `Yes, we do. You should ask sending banks to use this system if possible. SWIFT gpi is a new generation SWIFT
          payment system that is only supported by a limited number of banks at the moment If you or your counterparties 
          send funds to us using SWIFT gpi, you will receive a UETR code (Unique End-to-End Transaction Reference) for your
          transfer which you can use to check the progress of the transfer in real time and see the fees charged by the 
          various agent banks in a more transparent manner. As soon as you are aware that a SWIFT gpi payment has been 
          sent, you just need to send a copy to your International Private Banker. Our Operations Department can then 
          typically see the payment on the system immediately. At the moment we unfortunately do not have this facility 
          for outgoing payments due to the multiple rails we use. We are seeking to implement it in the near future.`,
    key: '7',
  },
  {
    title: 'Do you provide direct IBAN numbers?',
    text: `Yes, we do. IBAN stands for International Bank Account Number and is a standardized system for account 
          numbers that is used mainly in Europe and the Middle East. It is not used in other major markets like North America
          or Asia. Currently our jurisdiction is not part of the IBAN system, but we have arranged with our correspondents
          in Europe to offer virtual IBAN accounts in European currencies such as EUR, GBP, CHF. The advantage of this 
          is that your wire instructions are simpler and clearer, reducing the potential for error.A virtual IBAN account 
          means that you will appear to have an account in a European financial institution in your name, and our bank’s 
          name will not appear in the coordinates. This is also known as a “Payable Through” account. However, as soon 
          as funds hit the virtual account they will be immediately credited to your Wall Street Ibu Multi-Currency Account.
          Virtual IBANs are a convenience service for our Private Banking Clients. Our correspondents may restrict their 
          use for residents or citizens of certain countries. If this applies to you, you can still receive SWIFT transfers
          through the conventional method, using our SWIFT code WSBOKMK1 or via other 
          payment methods.`,
    key: '8',
  },
  {
    title: 'Do you accept check or cash deposits?',
    text: `As a digital bank, we do not accept check or cash deposits. Paper checks are no longer in common use for 
         international payments. If you need to deposit funds by cash or check to your Wall Street Ibu account, we do
         have solutions in certain countries via third party partners such as bureaux de change or cambio offices. Please 
         inquire about availability of these services with your International Private Banker.`,
    key: '9',
  },
]

const { useToken } = theme

const Faq = () => {
  const { token } = useToken()

  return (
    <div className={s.wrapper}>
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className={s.motion_div}>
        <div className={s.content}>
          <Hero title_1='FAQ' description="Here, we have compiled answers to the most common inquiries to help you navigate our services and find the information you need quickly and efficiently. Whether you're looking for detailed explanations about our offerings, troubleshooting guidance, or insights into our policies, this section is designed to address your concerns. If you don't find the answer you're looking for, please don't hesitate to contact us directly—our team is always ready to assist."/>
          <div className={s.collapse_block}>
            {dataForCollapse.map(item => (
              <Collapse
                key={item.key}
                className={s.my_collapse}
                expandIconPosition={'end'}
                bordered={false}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <img className={s.icon_minus} src={minus} alt='' />
                  ) : (
                    <img src={plus} className={s.icon_plus} alt='' />
                  )
                }
                style={{
                  background: 'transparent',
                  alignItems: 'center',
                  transition: token.motionEaseInOut,
                }}>
                <Panel
                  header={item.title}
                  key={item.key}
                  className={s.panelStyle}>
                  <div className={s.collapse_text}>
                    <p>{item.text}</p>
                  </div>
                </Panel>
              </Collapse>
            ))}
          </div>
        </div>

        <Contact />
      </motion.div>
    </div>
  )
}

export default Faq
