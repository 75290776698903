import React from 'react'
import s from './Contact.module.css'
import ContactUsForm from '../../components/contactUsForm/ContactUsForm'
import MyPopupSendSuccess from '../../components/ui/MyPopupSendSuccess/MyPopupSendSuccess'
import { useAppSelector } from '../../hooks/redux'
import { motion } from 'framer-motion'
import { EnvironmentOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import Hero from '../../components/hero/Hero'
import { useLocation } from 'react-router-dom'
import { CONTACT } from '../../router/Constants'
import telegram_icon_light from '../../assets/img/telegram_icon_light.svg'
import whatsapp_icon_light from '../../assets/img/whatsapp_icon_light.svg'

const Contact = () => {
  const location = useLocation()
  const isSuccess: boolean | null = useAppSelector(
    state => state.userReducer.isMessageSentSuccess
  )

  return (
   <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className={s.motion_div}> 

        {location.pathname === CONTACT && (
          <div className={s.content}>
            <Hero title_1='Contact' title_2='Us' description='We’re here to help! Whether you have a question, need assistance, or want to explore how we can work together, our team is just a message away. Let’s connect and make things happen.'/>
          </div>
        )}

    <div className={s.content}>
      <div className={s.container}>
      
        {isSuccess ? <MyPopupSendSuccess /> : <></>}

        <div className={s.leftColumn}>
        <ContactUsForm />
      </div>
      <div className={s.rightColumn}>
        <div className={s.rightColumnContent}>
        <h1 className={s.heading}>Contact Us</h1>
        <p className={s.paragraph}>
          You can contact us by mail or phone number. Or you can leave your contact details, and our managers will contact you.
        </p>

        <div className={s.contactSection}>
          <div className={s.iconHeading}>
            <EnvironmentOutlined className={s.icon} />
            <div className={s.contentHeading}>Representative Office</div>
          </div>
          <div className={s.contentDesc}>
          <p>No. 802 Renmin Avenue,</p>
          <p>Changchun City, Jilin Province</p>
          </div>
        </div>

        <div className={s.contactSection}>
          <div className={s.iconHeading}>
            <MailOutlined className={s.icon} />
            <div className={s.contentHeading}>Our Email</div>
          </div>
          <p className={s.contentDesc}>info@wallsteetibu.com</p>
        </div>

        <div className={s.contactSection}>
          <div className={s.iconHeading}>
            <PhoneOutlined className={s.icon} />
            <div className={s.contentHeading}>Contact</div>
          </div>
          <p className={s.contentDesc}>+271 386-647-3637</p>
          <div className={s.social}>
            <a href='https://t.me/WallstreetIBU'>
              <img className={s.icon} src={telegram_icon_light} alt="telegram" />
            </a>
            <a href='https://wa.me/+2713866473637'>
              <img className={s.icon} src={whatsapp_icon_light} alt="whatsapp" />
            </a>
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
   </motion.div> 
  )
}

export default Contact
