import React from 'react'
import s from './PrivateBanking.module.css'
import check_circle_blue from '../../assets/img/check_circle_blue.svg'
import private_banking_banner from '../../assets/img/private_banking_banner.png'
import { motion } from 'framer-motion'
import Hero from '../../components/hero/Hero'

const PrivateBanking = () => {
  return (
    <div className={s.wrapper}>
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className={s.motion_div}>
        <div className={s.content}>
          <Hero title_1='Private' title_2='Banking' description='Wall Street IBU is an international private bank! The Bank is incorporated in Fomboni, Comoros Islands, and has a team of International Private Bankers working digitally around the world providing almost 24/7 service.'/>

          <div className={s.flex}>
            <div className={s.left}>
              <p>The hallmark of Private Banking is exemplary personal, bespoke service. No two clients are the same, so flexibility is a cornerstone. Tell us about the banking service you need, and your Wall Street IBU International Private Banker will put it in place. <span className={s.bold}>Not even challenging tasks can fluster us.</span></p>
              <p>We have a long history of financial stability, working with complex, multi-jurisdictional entities, individuals, and families to protect and enhance wealth. Our personal approach to preserving and growing your wealth starts with our core focus of accounts featuring above average returns, hedging market risk volatility. We achieve this by making only highly selective loans, secured against liquid assets and prime real estate in major cities worldwide. We offer accounts to those who need an international presence. An overseas current or savings account provides flexibility, discretion and in many cases enhanced security and confidentiality.</p>
              <p>We can boast outstanding specialist expertise in the field of digital assets and blockchain banking, in the development of fund solutions, in tokenization, in the setting up of capital market issues – and, of course, also in conventional banking. We also provide a wealth of expertise in the field of HNWI mortgages.</p>
            </div>
            <div className={s.right}>
              <img src={private_banking_banner} alt="" />
            </div>
          </div>

          <div className={s.services_list_block}>
            <div className={s.services_list_block_title}>
              We offer the following core private banking services, delivered
              through a secure and cutting edge digital channel:
            </div>
            <div className={s.flex}>
              <div className={s.left}>
                <div className={s.line}>
                  <div className={s.check_circle} />
                  <div className={s.line_text}>
                    Multi-currency deposit account supporting all major and some
                    exotic currencies plus crypto currencies
                  </div>
                </div>
                <div className={s.line}>
                  <div className={s.check_circle} />
                  <div className={s.line_text}>
                    Custody of digital assets and execution services
                  </div>
                </div>
                <div className={s.line}>
                  <div className={s.check_circle} />
                  <div className={s.line_text}>Trading and brokerage</div>
                </div>
                <div className={s.line}>
                  <div className={s.check_circle} />
                  <div className={s.line_text}>
                    Bespoke investment solutions
                  </div>
                </div>
                <div className={s.line}>
                  <div className={s.check_circle} />
                  <div className={s.line_text}>
                    Foreign Exchange – spot, forward, swap and lending
                  </div>
                </div>
              </div>
              <div className={s.right}>
                <div className={s.line}>
                  <div className={s.check_circle} />
                  <div className={s.line_text}>
                    OTC and Derivatives Instruments
                  </div>
                </div>
                <div className={s.line}>
                  <div className={s.check_circle} />
                  <div className={s.line_text}>
                    Precious Metals Trading, Custody and Lending
                  </div>
                </div>
                <div className={s.line}>
                  <div className={s.check_circle} />
                  <div className={s.line_text}>
                    Payments, Transfers, and Credit Cards
                  </div>
                </div>
                <div className={s.line}>
                  <div className={s.check_circle} />
                  <div className={s.line_text}>
                    Lombard loans and mortgages secured by digital assets,
                    securities and cash balances
                  </div>
                </div>
                <div className={s.line}>
                  <div className={s.check_circle} />
                  <div className={s.line_text}>Concierge services</div>
                </div>
              </div>
            </div>
          </div>

          <div className={s.gradient}>
            <div className={s.gradient_title}>
              The Private Banking relationship is based on a hub Multi-Currency
              Account (MCA) The Wall Street Ibu Multi-Currency Account offers
              international clients the ultimate convenience with their foreign
              currency transactions. Your MCA will enable you to:
            </div>
            <div className={s.flex}>
              <div className={s.left}>
                <div className={s.line}>
                  <img src={check_circle_blue} alt='' />
                  <div className={s.gradient_text}>
                    Consolidate and manage foreign receivables and payments more
                    efficiently
                  </div>
                </div>
                <div className={s.line}>
                  <img src={check_circle_blue} alt='' />
                  <div className={s.gradient_text}>
                    Send and receive wire payments and settle your credit card
                    bills without currency conversion
                  </div>
                </div>
                <div className={s.line}>
                  <img src={check_circle_blue} alt='' />
                  <div className={s.gradient_text}>
                    Conduct transactions in most major world currencies.
                  </div>
                </div>
              </div>
              <div className={s.right}>
                <div className={s.line}>
                  <img src={check_circle_blue} alt='' />
                  <div className={s.gradient_text}>
                    Allow for currency diversification as an investment option
                    including our “Invest Loan” program
                  </div>
                </div>
                <div className={s.line}>
                  <img src={check_circle_blue} alt='' />
                  <div className={s.gradient_text}>
                    View balances and activities for all currency accounts
                    online.
                  </div>
                </div>
              </div>
            </div>
            <div className={s.gradient_footer}>
              All clients will be allocated a private banker so they know
              immediately who to contact whenever any banking services are
              needed. Our private bankers speak most global languages. You can
              initiate the account opening process online, or schedule a call
              with one of our International Private Banking team today!
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default PrivateBanking
