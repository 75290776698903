import React from 'react'
import s from './Home.module.css'
import CustomCard from '../../components/ui/customCard/CustomCard'
import who_we_are_img from '../../assets/img/who_we_are_img.jpg'
import artboard from '../../assets/img/artboard.png'
import lock from '../../assets/img/lock.png'
import { NavLink, useNavigate } from 'react-router-dom'
import {
  ABOUT_US,
  CARDS,
  COMMERCIAL_BANKING,
  DIGITAL_ASSETS_BANKING,
  OPEN_ACCOUNT,
  PRIVATE_BANKING,
  TRADE_FINANCE,
} from '../../router/Constants'
// import { useFormik } from 'formik'
// import { sendFormDataThunk } from '../../store/reducers/ActionCreators'
import { useAppSelector } from '../../hooks/redux'
import MyPopupSendSuccess from '../../components/ui/MyPopupSendSuccess/MyPopupSendSuccess'
import { motion } from 'framer-motion'
import CustomBtnBlue from '../../components/ui/customBtnBlue/CustomBtnBlue'
import classNames from 'classnames'
import Contact from '../contact/Contact'
import Hero from '../../components/hero/Hero'

const Home = () => {
  const navigate = useNavigate()
  // const dispatch = useAppDispatch()
  const isSuccess: boolean | null = useAppSelector(
    state => state.userReducer.isMessageSentSuccess
  )

  // const formikMail = useFormik({
  //   initialValues: {
  //     email: '',
  //   },
  //   onSubmit: values => {
  //     const data = {
  //       subject: 'Get Started',
  //       data: values,
  //     }
  //     dispatch(sendFormDataThunk(data))
  //   },
  // })

  const cards = [
    {
      title: 'Private Banking',
      short: 'PB',
      description:
        'The hallmark of Private Banking is exemplary personal, bespoke service. No two clients are the ' +
        'same, so flexibility is a cornerstone. Tell us about the banking service you need, and your Wall Street Bank' +
        ' International Private Banker will put it in place. Not even challenging tasks can fluster us. ',
      link: PRIVATE_BANKING,
    },
    {
      title: 'Commercial Banking',
      short: 'CB',
      description:
        'Businesses can also benefit from the private banking touch! If you are looking for a reliable ' +
        'global trade partner to help you handle transactions and create wealth across borders, talk to our expert ' +
        'bankers in the areas of complex payment transactions and trade finance.',
      link: COMMERCIAL_BANKING,
    },
    {
      title: 'Cards',
      short: 'C',
      description:
        'At Wall Street Ibu we allow you to protect, access and grow your wealth from anywhere in the' +
        ' world with an international bank account in a safe jurisdiction. To access your funds on the move, you ' +
        'will receive one or more global debit cards for seamless transactions.',
      link: CARDS,
    },
    {
      title: 'Digital Assets Banking',
      short: 'DAB',
      description:
        'We provide comprehensive banking services for both established and startup companies from the ' +
        'blockchain and cryptocurrency world, as well as offering private banking services to high net worth ' +
        'individuals who are trading or investing in crypto assets. ',
      link: DIGITAL_ASSETS_BANKING,
    },
    {
      title: 'Trade Finance',
      short: 'TF',
      description:
        'As a Wall Street Ibu corporate account holder, you are guaranteed direct contact with a Trade' +
        ' Finance and Documentary Credit expert. This way our clients have their instruments endorsed by prime ' +
        'banks without the undoubted inconvenience of having to deal with the large institutions.',
      link: TRADE_FINANCE,
    },
  ]

  return (
    <div className={s.wrapper}>
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className={s.motion_div}>
        {isSuccess ? <MyPopupSendSuccess /> : <></>}

        <div className={s.content}>
          <Hero title_1="Discover a World of" title_2='Financial Freedom!' description='Discover a world of financial freedom with Wall Street IBU. We specialize in providing offshore banking solutions tailored to meet your needs. Enjoy privacy, security, and flexibility with our range of services.' buttons/>
        </div>

        <div className={s.who_we_are}>
          <div className={s.content}>
            <div className={s.flex}>
              <div className={s.left}>
                <h2 className={s.subtitle}>Who we are?</h2>
                <p><span className={s.bold}>Wall Street IBU</span> is an international private bank serving the unique needs of globally mobile and digitally active citizens, their families and their businesses.</p>
                <p>It is a thoroughly modern International Private Banking brand that boasts a long history and a long-term view of the future.</p>
                <p>The strength of our bank lies in the diversity of our International Private Bankers.</p>
                <p>Our team speaks a dozen languages and uses its skills to serve private and institutional customers by offering personalized relationships and individual financial solutions.</p>
                <CustomBtnBlue title='learn more' onClick={()=>navigate(ABOUT_US)} />
              </div>
              <div className={s.right}>
                <img src={who_we_are_img} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className={s.content}>
          <div className={s.cards_wrapper}>
            <div className={s.cards_title}>Services we provide</div>
            {cards.map(card => {
              return (
                <div className={s.card_block} key={card.title}>
                  <NavLink to={card.link} reloadDocument>
                    <CustomCard
                      title={card.title}
                      short={card.short}
                      description={card.description}
                    />
                  </NavLink>
                </div>
              )
            })}
            <div className={s.btn_card}>
              <CustomBtnBlue title='open account' onClick={() => navigate(OPEN_ACCOUNT)} />
            </div>
          </div>
        </div>
        
        <div className={s.content}>
          <div className={s.payment_method}>
            <div className={s.left}>
              <img src={lock} alt="lock" />
            </div>
            <div className={s.right}>
              <h2>We Are Fast and Secure Payment Method</h2>
              <p>At <span className={classNames(s.bold, s.italic)}>Wall Street IBU,</span> we believe in empowering individuals and businesses with exceptional offshore banking services. Established in 2022, we have built a reputation for trust, integrity, and innovation in the financial industry. Our team of experts is dedicated to helping you navigate the complexities of offshore banking with ease.</p>
              <CustomBtnBlue title='learn more' onClick={()=>navigate(ABOUT_US)}/>
            </div>
          </div>
        </div>

        <div className={s.open_account_section}>
          <div className={s.content}>
            <div className={s.inner_content}>
              <div>
                <h2>WALL Street IBU</h2>
                <p>is an International Private Bank serving the unique needs of globally mobile and digitally active citizens, their families and their businesses. </p>
                <CustomBtnBlue title='open account' onClick={()=>navigate(OPEN_ACCOUNT)}/>
              </div>
            </div>
            <img className={s.artboard} src={artboard} alt="img" />
          </div>
        </div>

        <div className={s.contact_form_wrapper}>
          <div className={s.content}>
            <Contact/>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Home
