import React, {ChangeEvent, useEffect, useState} from 'react';
import s from './CustomInput.module.css'
import classnames from "classnames";

type PropsType = {
    placeholder?: string
    id:string
    name:string
    type:string
    onChange: { (e: ChangeEvent<any>): void;}
    value: string
    required? : boolean
    onFocus?: () => string
    ref?: any
    error?: boolean
    pattern?: string
    isError?: string
    password?: boolean
    label?: string
}

const CustomInput:React.FC<PropsType> = ({...props}) => {

    const [inputType, setInputType] = useState("text")

    useEffect(() => {
        if (props.password) {
            setInputType("password")
        }
    }, [])

    const changeInputType = (e: any) => {

        e.preventDefault()

        if (inputType === "password") {
            setInputType("text")
        }

        if (inputType === "text") {
            setInputType("password")
        }
    }

    return (
        <div className={s.input_wrapper}>
            {props.label && <div className={s.label}>{props.label}</div>}
            <input pattern={props.pattern}
                   {...props}
                   ref={props.ref}
                   className={classnames(props.isError, s.input)}
                   type={inputType}
            />
            <div className={props.password ? s.view : s.hidden}>
                <a href="#" className={inputType === "text" ? s.passwordView : s.passwordControl}
                   onClick={(e) => changeInputType(e)}>
                </a>
            </div>
        </div>
    );
};

export default CustomInput;
