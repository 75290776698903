import React from 'react';
import { useFormik } from 'formik';
import CustomInput from "../ui/customInput/CustomInput";
import s from './ContactUsForm.module.css';
import { useAppDispatch } from "../../hooks/redux";
import { sendFormDataThunk } from "../../store/reducers/ActionCreators";
import * as yup from 'yup';
import MyBtn from '../ui/MyBtn/MyBtn';

const validationSchema = yup.object({
    // phone: yup.number().required('Required field'),
    email: yup.string().required('Required field').email('Invalid format'),
});

const ContactUsForm = () => {
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            message: '',
        },
        validationSchema,
        onSubmit: values => {
            const data = {
                subject: "Contact Form",
                data: values,
            };
            dispatch(sendFormDataThunk(data));
        },
    });

    return (
        <div className={s.form_wrapper}>
            <div className={s.form_content}>
                <form onSubmit={formik.handleSubmit}>
                    <CustomInput
                        id="firstName"
                        name="firstName"
                        type="text"
                        label="First Name"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        placeholder="Enter your first name"
                        pattern="[a-z, A-Z]*"
                    />
                    <CustomInput
                        id="lastName"
                        name="lastName"
                        type="text"
                        label="Last Name"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        placeholder="Enter your last name"
                        pattern="[a-z, A-Z]*"
                    />
                    <CustomInput
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        label="Email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        isError={formik.errors.email ? s.input_error : ""}
                    />
                    <span className={s.label}>Message</span>
                    <textarea
                        id="message"
                        name="message"
                        placeholder="Message"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        className={s.textarea}
                    />
                    <div className={s.btn_block}>
                        <MyBtn type='submit' title="Send Message" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactUsForm;
