import React from 'react'
import s from './Cards.module.css'
import cards from '../../assets/img/wallet.png'
import cards_mobile from '../../assets/img/mobile/wallet.png'
import CustomBtnPink from '../../components/ui/customBtnPink/CustomBtnPink'
import { OPEN_ACCOUNT } from '../../router/Constants'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import card_image1 from '../../assets/img/cadsBolockImg1.png'
import card_image2 from '../../assets/img/cardsBlockImg2.png'
import Hero from '../../components/hero/Hero'

const Cards = () => {
  const navigate = useNavigate()

  return (
    <div className={s.wrapper}>
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className={s.motion_div}>
        <div className={s.content}>
          <Hero title_1='Cards' description='Not long ago, it wasn’t all that unusual to have to carry large amounts of cash when traveling. Today, we can conveniently pull out a debit or credit card or a mobile device and settle the bill electronically there and then. It is difficult now to imagine a time when these card-based payment options were not available.'/>
          {/* <div className={s.who_we_desc_block}>
            <div className={s.who_we_description}>
              <div>
                Not long ago, it wasn’t all that unusual to have to carry large
                amounts of cash when traveling. Today, we can conveniently pull
                out a debit or credit card or a mobile device and settle the
                bill electronically there and then.
              </div>
              <div>
                It is difficult now to imagine a time when these card-based
                payment options were not available.
              </div>
            </div>
          </div> */}


          <div className={s.card_block_desc}>
            <div className={s.card_block_desc_left}>
            <p className={s.card_block_desc_paragraph}>
            Our customers using debit and credit cards enjoy the convenience that these cards bring in, for improved financial management.
              </p>
              <p className={s.card_block_desc_paragraph}>
              In the background, Wall Street IBU also works with partners internationally and payment service providers and is primarily responsible for cash management, the coordination of data and payment flows and for all licensing and compliance tasks, adding the security of a regulated offshore bank as a party to the transactions.
              </p>
            </div>
            <div className={s.card_block_desc_right}>
            <img className={s.desc_image} src={card_image1} alt="card_block Description" />
            </div>
          </div>
       

          <div className={s.cards_block}>
            <div className={s.flex}>
              <div className={s.cards_description_block}>
                <div className={s.cards_description_content}>
                  At Wall Street Ibu we allow you to protect, access and grow
                  your wealth from anywhere in the world with an international
                  bank account in a safe jurisdiction.
                </div>
                <div className={s.cards_description_content}>
                  To access your funds on the move, you will receive one or more
                  global debit cards for seamless transactions.
                </div>
                {/* <div className={s.cards_description_content}>
                  Debit cards are available to clients maintaining an account
                  balance of at least 25,000 EUR/USD/GBP. They can be pre-loaded
                  and used to make purchases or cash withdrawals in almost any
                  country of the world.
                </div> */}
                <div className={s.cards_button_block}>
                  <div className={s.cards_wrapper}>
                    <CustomBtnPink
                      title={'Sign Up'}
                      onClick={() => navigate(OPEN_ACCOUNT)}
                    />
                  </div>
                </div>
              </div>
              <div className={s.cards_img_block}>
                <img src={cards_mobile} className={s.cards_mobile} alt='' />
                <img src={cards} className={s.cards} alt='' />
              </div>
            </div>
          </div>

          <div className={s.card_block_desc}>
            <div className={s.card_block_desc_left}>
            <img className={s.desc_image} src={card_image2} alt="card_block Description" />
            </div>
            <div className={s.card_block_desc_right}>
            
            <p className={s.card_block_desc_paragraph}>
            Our customers using debit and credit cards enjoy the convenience that these cards bring in, for improved financial management.
              </p>
              <p className={s.card_block_desc_paragraph}>
              In the background, Wall Street IBU also works with partners internationally and payment service providers and is primarily responsible for cash management, the coordination of data and payment flows and for all licensing and compliance tasks, adding the security of a regulated offshore bank as a party to the transactions.
              </p>
            </div>
          </div>
        </div>

      </motion.div>
    </div>
  )
}

export default Cards
